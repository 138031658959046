<template>
  <div class="discussions">
    <h1>Discussions</h1>
    <div class="discussion-list">
      <div v-for="(discussion, index) in discussions" :key="index" class="discussion-card">
        <div class="discussion-content">
          <div class="discussion-text">{{ discussion.text }}</div>
          <div class="discussion-links">
            <a :href="discussion.link" target="_blank" class="link-button slides">slides</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscussionsPage',
  data() {
    return {
      discussions: [
        {
          text: 'Can the Fed Control Inflation? Stock Market Implications by Daniel Andrei and Michael Hasler (NFA 2024)',
          link: 'https://www.dropbox.com/scl/fi/pr8ujcdr2z6jtxojtjobl/NFA_2024_Discussion_CHL.pdf?rlkey=uivujcmq592judoh46d12dcs9&st=0rb3hbvj&dl=0'
        },
        {
          text: 'How Effective are Portfolio Mandates? by Jack Favilukis, Lorenzo Garlappi, and Raman Uppal (12th Workshop on Investment and Production-Based Asset Pricing 2024)',
          link: 'https://www.dropbox.com/scl/fi/uk2d2901n87u7dvpsayxt/CHLDiscussion_Production_Based_Asset_Pricing_2024.pdf?rlkey=w417l1xwes7dsixnfr6sma3n2&st=yy4r9ejq&dl=0'
        },
        {
          text: 'Operating Leverage and Risk Premium by Leonid Kogan, Jun Li, Harold H. Zhang, and Yifan Zhu (BOCCONI - BAFFI - CEPR CONFERENCE ON ASSET PRICING 2024)',
          link: 'https://www.dropbox.com/scl/fi/13nt6a2yvdeppkabyisg0/OperatingLeverageDiscussionCHL.pdf?rlkey=9f5weuaj2vg11x1zeb9l5lb7n&st=xq6dszyj&dl=0'
        },
        {
          text: 'The Rise of Alternatives by Juliane Begenau, Pauline Liang, Emil Siriwardane (Women in Central Banking 2023)',
          link: 'https://www.dropbox.com/scl/fi/e59na7kukkeb5l9x9pavk/discussionCHL.pdf?rlkey=pxoew0eenwjmx3o26bhzjj3rp&st=gw9nxkse&dl=0'
        },
        {
          text: 'Discount Rates, Labor Market Dynamics, and Income Risk by Meeuwis, Papanikolaou, Rothbaum and Schmidt (2nd Holden Conference)',
          link: 'https://www.dropbox.com/scl/fi/x06r0bu0czo5qrb4fkf21/CHLDiscussion.pdf?rlkey=in806z4fl8dl4oey9chmf6rp1&st=3do1p31d&dl=0'
        },
        {
          text: 'Exorbitant Privilege and Exorbitant Duty by Pierre-Olivier Gourinchas and Hélène Rey (Paul Woolley Centre Conference 2023)',
          link: 'https://www.dropbox.com/scl/fi/rcstrp99ev77bgb72xp1g/CHLPWLSE2023.pdf?rlkey=iv4d15tistlawmzoq2gdiin6t&st=rgtx69wp&dl=0'
        },
        {
          text: 'A Long and a Short Leg Make For a Wobbly Equilibrium by Garleanu, Panageas, and Zheng (BISHOF 2023)',
          link: 'https://www.dropbox.com/scl/fi/zdr86eqog37n8fcaqi1i2/CHLBISHoF.pdf?rlkey=hib46k2e0a50ui0tbfp86961h&st=sfoklp14&dl=0'
        },
        {
          text: 'Pricing Climate Linkers by Pauline Chikhani and Jean-Paul Renne (IBEFA 2023)',
          link: 'https://www.dropbox.com/scl/fi/ezymvlyobdb1x3rrn0p2i/IBEFA2022.pdf?rlkey=im2nihpcnxlhal9kiqgnqxv1s&st=5toe5fd1&dl=0'
        },
        {
          text: 'Quantifying Dark Matter in Finance: Time-varying Disaster Risk and Asset Prices by Matthew Baron, Wei Xiong, and Zhijiang Ye (EFA 2022)',
          link: 'https://www.dropbox.com/s/lqujygauueiewrb/CHLEFA2022.pdf?dl=0'
        },
        {
          text: 'Market Response to A VIX Impulse by Nikolaus Hautsch, Albert J. Menkveld and Stefan Voigt (FIRS 2022)',
          link: 'https://www.dropbox.com/s/uplqye29c31icd3/CHLFIRS.pdf?dl=0'
        },
        {
          text: 'Concealed Carry by S. Andrews, R. Colacito, M. M. Croce and F. Gavazzoni (HEC-McGill Winter Finance Workshop 2022)',
          link: 'https://www.dropbox.com/s/soj4l313hkipl2u/CHLConcealedCarry.pdf?dl=0'
        },
        {
          text: 'Dealer Disagreement and Asset Prices in FX Markets by Brandon Yueyang Han, Sophia Zhengzi Li and Zhaogang Song (YSFC 2022)',
          link: 'https://www.dropbox.com/s/5v6667wj9u6vvqy/CHLYSFCDiscussion.pdf?dl=0'
        },
        {
          text: 'Volatility (Dis)Connect in International Markets by R. Colacito, M. M. Croce, Y. Liu, I. Shaliastovich (MFA 2022)',
          link: 'https://www.dropbox.com/s/mcjho53w4ta709m/CHLVoldisconnect.pdf?dl=0'
        },
        {
          text: 'Information-Driven Volatility by Hengjie Ai, Leyla Jianyu Han, and Lai Xu (MFA 2022)',
          link: 'https://www.dropbox.com/s/46cl75fakhxpaam/CHLInfoVolatility.pdf?dl=0'
        },
        {
          text: 'Monetary Policy, Segmentation, and the Term Structure by Kekre, Lenel and Mainardi (MFA 2022)',
          link: 'https://www.dropbox.com/s/xl5emoxylvrpf0p/CHLMonetaryPolicySegmentationTS.pdf?dl=0'
        },
        {
          text: 'The Safety Premium of Safe Assets by Jens H. E. Christensen and Nokola Mirkov (MFA 2021)',
          link: 'https://www.dropbox.com/s/b8fqelen47y2kf9/Safety.pdf?dl=0'
        },
        {
          text: 'U.S. Banks and Global Liquidity by Ricardo Correa, Wenxin Du and Gordon Y. Liao (FRIC 2021)',
          link: 'https://www.dropbox.com/s/cqnywo0t93j3192/FRIC2021.pdf?dl=0'
        },
        {
          text: 'Cognitive Errors As Canary Traps by Alex Chinco (European Winter Finance Conference 2021)',
          link: 'https://www.dropbox.com/s/r1hq41yfaljmxat/EWFC2021.pdf?dl=0'
        },
        {
          text: 'Inequality Aversion, Populism and the Backlash Against Globalization by Lubos Pastor and Pietro Veronesi (EFA 2020)',
          link: 'https://www.dropbox.com/s/m7ilgj8yyynye2f/InequalityAversion2.pdf?dl=0'
        },
        {
          text: 'Heterogeneous Beliefs and FOMC Announcements by Chao Ying (MFA 2020)',
          link: 'https://www.dropbox.com/s/qk8tp6ibj2l7iiw/HBFA.pdf?dl=0'
        },
        {
          text: 'How Risky are the U.S. Corporate Assets? by Tetiana Davydiuk, Scott Richard, Ivan Shaliastovich, and Amir Yaron (MFA 2020)',
          link: 'https://www.dropbox.com/s/qnhr37dxep04fpo/TotalPayout.pdf?dl=0'
        },
        {
          text: 'The Network of Firms Implied by the News by Schwenkler and Zheng (NFA 2019)',
          link: 'https://www.dropbox.com/s/sfaz3fqhw9hvo5f/NFA2019_DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Asset Pricing with Fading Memory by Stefan Nagel and Zhengyang Xu (EFA 2019)',
          link: 'https://www.dropbox.com/s/ufb82n7xlq8wy2b/EFA2019_DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'A Production-based Economic Explanation for the Gross Profitability Premium by Leonid Kogan, Jun Li and Harold H. Zhang (Swedish House of Finance - Financial markets and corporate decisions, 2019)',
          link: 'https://www.dropbox.com/s/uv3zoez994zyk7l/SHoF-DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Risk Sharing and the Term Structure of Interest Rates by Andrés Schneider (MFA 2019)',
          link: 'https://www.dropbox.com/s/cnuoakuz15ogqql/TSCHL.pdf?dl=0'
        },
        {
          text: 'Expected Correlation and Future Market Returns by Buss, Schönleber and Vilkov (AFA 2019)',
          link: 'https://www.dropbox.com/s/cdr1sj7zpgaugzh/ExpectedCorr_CHL.pdf?dl=0'
        },
        {
          text: 'Funding Constraints and Informational Efficiency by Sergei Glebkin, Naveen Gondhi, and John Kuong (EFA 2018)',
          link: 'https://www.dropbox.com/s/wvzy9sdxsaias63/EFA2018_DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Pricing Implications of Covariances and Spreads in Currency Markets by Thomas A. Maurer, Thuy-Duong Tô, Ngoc-Khanh Tran (Vienna Symposium on Foreign Exchange Markets, 2018)',
          link: 'https://www.dropbox.com/s/xncwt1ny9pq7v2t/VSFX-DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'The Collateralizability Premium by Hengjie Ai, Jun Li, Kai Li and Christian Schlag (CAPR, 2018)',
          link: 'https://www.dropbox.com/s/nsvo33g01fiapng/CAPR%202018%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Financial Innovation and Asset Prices by Adrian Buss, Raman Uppal and Grigory Vilkov (Third European Workshop on Household Finance, 2018)',
          link: 'https://www.dropbox.com/s/18lmrb70gz60jna/HouseholdFinance%20Discussion%20CHL.pdf?dl=0'
        },
        {
          text: 'Dissecting Characteristics Nonparametrically by J. Freyberger, A. Neuhierl and M. Weber (European Winter Finance Summit, 2018)',
          link: 'https://www.dropbox.com/s/nekrb04gugbc7xp/EWFS%20Discussion%20Final.pdf?dl=0'
        },
        {
          text: 'An Equilibrium Theory of Determinate Nominal Exchange Rates,Current Accounts and Asset Flows by Marcus Hagedorn (7th Workshop on Financial Determinants of Foreign Exchange, 2017)',
          link: 'https://www.dropbox.com/s/5h65s3egskrof4k/Norges%20Bank%20FX%20workshop%202017%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Managing Counterparty Risk in OTC Markets by Christoph Frei, Agostino Capponi and Celso Brunetti (NFA, 2017)',
          link: 'https://www.dropbox.com/s/k9ps0fgyakma681/NFA%202017%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Preventing Controversial Catastrophes by Steven D. Baker, Burton Hollifield and Emilio Osambela (EFA, 2017)',
          link: 'https://www.dropbox.com/s/k9ps0fgyakma681/NFA%202017%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'An Information-Theoretic Asset Pricing Model by Anisha Ghosh, Christian Julliard and Alex P. Taylor (BI-SHoF, 2017)',
          link: 'https://www.dropbox.com/s/80wirnip1pzurwa/BI-SHoF%202017%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Winners and Losers: Creative Destruction and Stock Returns by Kogan, Papanikolaou and Stoffman (CAPR, 2017)',
          link: 'https://www.dropbox.com/s/qacmrpvorlrgzqo/CAPR%202017%20-%20DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Expected Term Structures by Andrea Buraschi, Ilaria Piatti and Paul Whelan (AFA, 2017)',
          link: 'https://www.dropbox.com/s/k7a8nnjwidpegix/ExpectedTS_CHL.pdf?dl=0'
        },
        {
          text: 'Pricing of Idiosyncratic Equity and Variance Risks by Elise Gourier (EFA, 2016)',
          link: 'https://www.dropbox.com/s/7bbhohlf3tl0fdm/EFA2016_EliseGourier_DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Equilibrium Asset Pricing in Directed Networks by Nicole Branger, Patrick Konermann, Christoph Meinerding and Christian Schlag (Fourth Economic Networks and Finance Conference, 2016)',
          link: 'https://www.dropbox.com/s/tbsngbr4r6z1a1k/NetworksLSE16_CHL.pdf?dl=0'
        },
        {
          text: 'Where Experience Matters: Asset Allocation and Asset Pricing with Opaque and Illiquid Assets by Adrian Buss, Raman Uppal and Grigory Vilkov (AFA, 2016)',
          link: 'https://www.dropbox.com/s/bc8g4nvpimakiko/Experience_CHL.pdf?dl=0'
        },
        {
          text: 'The Financialization of Storable Commodities by Steven D. Baker (EFA, 2015)',
          link: 'https://www.dropbox.com/s/cdou24kjscy1spb/EFA2015_Baker_DiscussionCHL.pdf?dl=0'
        },
        {
          text: 'Term Structures of Asset Prices and Returns by David Backhus, Nina Boyarchenko and Mikhail Chernov (BI-SHoF, 2015)',
          link: 'https://www.dropbox.com/s/xuc17ckbhb1leyd/BISHoF15.pdf?dl=0'
        },
        {
          text: 'Currency Risk Factors in a Recursive Multi-Country Economy by Ric Colacito, Mariano (Max) Massimiliano Croce, Federico Gavazzoni, Robert C. Ready (CAPR, 2015)',
          link: 'https://www.dropbox.com/s/vdmdlp8eeszgft8/CAPR15.pdf?dl=0'
        },
        {
          text: 'BKK the EZ Way. International Long-Run Growth News and Capital Flows by Ric Colacito, Mariano (Max) Massimiliano Croce, Steven Wei Ho and Philip Howard (AFA, 2015)',
          link: 'https://www.dropbox.com/s/42xg3tprumxfutz/BKKEZ_CHL.pdf?dl=0'
        },
        {
          text: 'A model of time-varying risk premia with habits and production by Ian Dew-Becker (CAPR, 2014)',
          link: 'https://www.dropbox.com/s/q5copomncoc3j15/CAPR14_CHL.pdf?dl=0'
        },
        {
          text: 'Asset Prices in General Equilibrium with Recursive Utility and Illiquidity Induced by Transactions Costs by Adrian Buss, Raman Uppal and Grigory Vilkov (EFA, 2013)',
          link: 'https://www.dropbox.com/s/g6qn0ctrj2o8cy5/BUV_Discussion_CHL.pdf?dl=0'
        },
        {
          text: 'A Production-Based Model for the Term Structure by Urban Jermann (CAPR, 2013)',
          link: 'https://www.dropbox.com/s/xnix6u07lmnebra/CAPR13_CHL.pdf?dl=0'
        },
        {
          text: 'Equilibrium Growth, Inflation, and Bond Yields by Howard Kung (EFA, 2012)',
          link: 'https://www.dropbox.com/s/m1s60q7hprkxilb/EquilibriumGrowth_Discussion_CHL.pdf?dl=0'
        },
        {
          text: 'A Robust Analysis of the Risk-Structure of Equilibrium Term Structures of Bond Yields by Anh Le and Kenneth J. Singleton (EFA, 2012)',
          link: 'https://www.dropbox.com/s/cine9yy2k24ey67/ETSM_CHL.pdf?dl=0'
        },
        {
          text: 'Volatility, the Macroeconomy and Asset Prices by Ravi Bansal, Dana Kiku, Ivan Shaliastovich and Amir Yaron (CAPR/NFI Workshop on Time-Varying Expected Returns, 2012)',
          link: 'https://www.dropbox.com/s/s0nin3w5r1f06r3/VolMacroAP_Discussion_CHL.pdf?dl=0'
        },
        {
          text: 'State-dependent dependencies: A continuous-time dynamics for correlations by Christoph Becker and Wolfgang Schmidt (World Finance Conference, 2011)',
          link: 'https://www.dropbox.com/s/x9t378e83f3p0hf/WFCDiscussion11.pdf?dl=0'
        },
        {
          text: 'Sources of Entropy in Dynamic Representative Agent Models by David Backus, Mikhail Chernov, and Stanley Zin (SIFR, 2010)',
          link: 'https://www.dropbox.com/s/qd2ulwbcj9ff4ij/Discussion-6.pdf?dl=0'
        },
        {
          text: 'When Uncertainty Blows in the Orchard: Comovement and Equilibrium Volatility Risk Premia by Andrea Buraschi, Fabio Trojani and Andrea Vedolin (WFA, 2010)',
          link: 'https://www.dropbox.com/s/8jj5goxldk3lhyx/OrchardWFADiscussion.pdf?dl=0'
        },
        {
          text: 'The CARMA Interest Rate Model by Arne Andresen, Fred Espen Benth, Steen Koekebakker and Valeriy Zakamulin (European Winter Finance Workshop, 2009)',
          link: 'https://www.dropbox.com/s/pt7n7ppfxtgtfst/CARMA%20-%20Discussion%2009.pdf?dl=0'
        },
        {
          text: 'Is the Bond Premium Puzzle Really a Puzzle? by Jesper Pedersen (2nd Nordic Summer Symposium in Macroeconomics, 2008)',
          link: 'https://www.dropbox.com/s/3ggkw31nndehsoe/Is%20the%20Bond%20Premium%20Puzzle%20Really%20a%20Puzzle.pdf?dl=0'
        }
      ]
    }
  }
}
</script>

<style scoped>
.discussions {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: 40px 0;
}

.discussion-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.discussion-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.discussion-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.discussion-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.discussion-text {
  color: var(--text-color, #ffffff);
  font-size: 1.1em;
  line-height: 1.5;
}

.discussion-links {
  display: flex;
  gap: 10px;
}

.link-button {
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--button-bg, #2ecc71);
  color: white;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
  color: white;
}

@media (max-width: 768px) {
  .discussion-content {
    gap: 10px;
  }
  
  .discussion-text {
    font-size: 1em;
  }
}

/* Light mode styles */
:root[data-theme="light"] h1 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .discussion-card {
  background-color: var(--card-bg, #f5f5f5);
}

:root[data-theme="light"] .discussion-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] .discussion-text {
  color: var(--text-color, #333333);
}
</style>
