<template>
  <div class="cv">
    <h1>Christian Heyerdahl-Larsen</h1>
    <div class="cv-card contact-info">
      <p>BI Norwegian Business School</p>
      <p>Mobile: +47 405 87 619</p>
      <p>Email: christian.heyerdahl-larsen@bi.no</p>
      <p>Website: <a href="http://christian.heyerdahllarsen.com" target="_blank">christian.heyerdahllarsen.com</a></p>
      <div class="button-container">
        <a class="link-button" href="/Resources/cv_Christian_Heyerdahl-Larsen-Most Recent.pdf" download>
          Download CV
        </a>
      </div>
    </div>

    <div class="cv-card">
      <h2>Employment</h2>
      <ul>
        <li>Professor of Finance - BI Norwegian Business School, 2023 -</li>
        <li>Daniel C. Smith Faculty Fellow, Indiana University, Kelley School of Business, 2022 - 2023</li>
        <li>Associate Professor (with tenure) - Indiana University, Kelley School of Business, 2020 - 2023</li>
        <li>Assistant Professor - Indiana University, Kelley School of Business, 2018 - 2020</li>
        <li>Assistant Professor - London Business School, 2010 - 2018</li>
        <li>Postdoctoral Research Fellow, SIFR - Institute for Financial Research, 2008 - 2010</li>
      </ul>
    </div>

    <div class="cv-card">
      <h2>Education</h2>
      <ul>
        <li>Ph.D. in Financial Economics, BI - Norwegian Business School, Norway, 2005 - 2009</li>
        <li>Visiting Ph.D. Student at Mays Business School at Texas A&M University, Spring 2008</li>
        <li>MSc Financial Mathematics (with distinction), City University - Sir John Cass Business School, London, 2004</li>
        <li>Siviløkonom (Master of Business and Economics), BI - Norwegian Business School, Norway, 2003</li>
      </ul>
    </div>

    <div class="cv-card">
      <h2>Fields of Interest</h2>
      <p>Asset Pricing, Fixed Income, International Finance, Heterogeneous Agent Models</p>
    </div>

    <div class="cv-card">
      <h2>Publications</h2>
      <ul>
        <li>"On Efficiency in Disagreement Economies," C. Heyerdahl-Larsen and J. Walden, <i>Social Choice and Welfare</i>, 2023, 61, 763–799</li>
        <li>"Distortions and Efficiency in Production Economies with Heterogeneous Beliefs," C. Heyerdahl-Larsen and J. Walden, <i>Review of Financial Studies</i>, 2022, 35 (4), 1775-1812</li>
        <li>"Asset Prices and Portfolio Choice with Learning from Experience," P. Ehling, A. Granniero and C. Heyerdahl-Larsen, <i>Review of Economic Studies</i>, 2018, 85 (3), 1752-1780.</li>
        <li>"Disagreement about Inflation and the Yield Curve," P. Ehling, M. Gallmeyer, C Heyerdahl-Larsen and P. Illeditsch, <i>Journal of Financial Economics</i>, 2018, 127 (3), 459-484.</li>
        <li>"Risk Premia and Volatilities in a Non-Linear Term Structure Model," P. Feldhütter, C. Heyerdahl-Larsen and P. Illeditsch, <i>Review of Finance</i>, 2018, 22 (1), 337-380.<br>
        <i class="award">Outstanding Paper Award, Wharton's Jacobs Levy Equity Management Center for Quantitative Research, 2014</i><br>
        <i class="award">Best Paper Award, World Finance Conference, 2013</i></li>
        <li>"Correlations," P. Ehling and C. Heyerdahl-Larsen, <i>Management Science</i>, 2017, vol. 63. no 6., 1919-1937.</li>
        <li>"Complete and Incomplete Financial Markets in Multi-Good Economies," P. Ehling and C. Heyerdahl-Larsen, <i>Journal of Economic Theory</i>, 2015, 160, 438-462.</li>
        <li>"Asset Pricing and Real Exchange Rates with Deep Habits," C. Heyerdahl-Larsen, <i>Review of Financial Studies</i>, 2014, 27 (11), 3280-3317.</li>
        <li>"Market Selection and Welfare in a Multi-Asset Economy," Y. Fedyk, C. Heyerdahl-Larsen and J. Walden, <i>Review of Finance</i>, 2013, 17, 1179-1237.</li>
      </ul>
    </div>

    <div class="cv-card">
      <h2>Working Papers</h2>
      <ul>
        <li>'International Capital Markets and Wealth Transfers" (with Magnus Dahlquist, Anna Pavlova and Julien Penasse), 2024</li>
        <li>"The Relative Price Premium" (with Yun Joo An, Fotis Grigoris and Preetesh Kantak), 2024</li>
        <li>"Model Selection by Market Selection" (with Philipp Illeditsch and Johan Walden), 2024</li>
        <li>"Asset Pricing with the Awareness of New Priced Risks" (with Philipp Illeditsch and Petra Sinagl), 2024</li>
        <li>"No Short in Sight" (with Paul Ehling and Zeshu Xu), 2024</li>
        <li>"Economic Growth through Diversity in Beliefs" (with Philipp Illeditsch and Howard Kung), 2024</li>
        <li>"Demand Disagreement" (with Philipp Illeditsch), 2023</li>
        <li>"Factor and stock-specific disagreement and trading flows" (with Fotis Grigoris and Preetesh Kantak), 2022</li>
        <li>"The Market View" (with Philipp Illeditsch), 2021</li>
        <li>"Asset Prices with Wealth Dispersion" (with Paul Ehling and Junjie Guo), 2021</li>
      </ul>
    </div>

    <div class="cv-card">
      <h2>Honors and Awards</h2>
      <ul>
        <li>Best Paper Award at the Vienna Symposium on Foreign Exchange Markets 2022</li>
        <li>Outstanding Paper Award, Wharton's Jacobs Levy Equity Management Center for Quantitative Research, 2014</li>
        <li>Best Paper Award, World Finance Conference, 2013</li>
        <li>Scholarship from Ernst & Young for best grade in Business Economics (2003)</li>
        <li>Finalist in the International Business Challenge (IBC) (2002), The University of Texas at Austin, UMCA</li>
        <li>Winner of the Professional Excellence Award at the International Business Challenge (IBC) (2002), UMCA</li>
      </ul>
    </div>

    <div class="cv-card">
      <h2>Teaching</h2>
      <ul>
        <li>BØK 3632 Finansiell Styring (in Norwegian), Undergraduate, BI Norwegian Business School (2023-)</li>
        <li>CA01 Investment Fundamentals, Masters in Financial Analysis, London Business School (2023) </li>
        <li>F300 Introduction to Financial Management, Undergraduate, Kelley School of Business (2020-2023)</li>
        <li>F625 Empirical Finance, Phd, Kelley School of Business (2019-)</li>
        <li>F571 International Corporate Finance, MBA, Kelley School of Business (2019, 2020)</li>
        <li>F570 International Financial Markets, MBA, Kelley School of Business (2019, 2020)</li>
        <li>F494 International Finance, Undergraduate, Kelley School of Business (2019)</li>
        <li>Finance II, MBA, London Business School (2018)</li>
        <li>Finance Masters in Management core course at London Business School (2015-2018)</li>
        <li>International Finance at London Business School (2010-2018)</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurriculumVitae'
}
</script>

<style scoped>
.cv {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: 40px 0;
}

h2 {
  color: var(--text-color, #ffffff);
  border-bottom: 2px solid var(--border-color, #444);
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 15px;
}

.cv-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 20px;
  color: var(--text-color, #ffffff);
}

.cv-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.contact-info {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
  color: var(--text-secondary, #cccccc);
}

a {
  color: var(--link-color, #2ecc71);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--link-hover-color, #27ae60);
}

.button-container {
  margin-top: 15px;
}

.link-button {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--button-bg, #2ecc71);
  color: white;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
  color: white;
}

i {
  font-style: italic;
  color: var(--text-secondary, #b3b3b3);
}

.award {
  display: block;
  margin-top: 2px;
  font-size: 0.9em;
  /* color: var(--award-color, #ffd700); */
}

p {
  color: var(--text-secondary, #cccccc);
  margin: 5px 0;
}

@media (max-width: 768px) {
  .cv {
    padding: 10px;
  }
  
  .cv-card {
    padding: 15px;
  }
}

/* Light mode styles */
:root[data-theme="light"] .cv-card {
  background-color: var(--card-bg, #f5f5f5);
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .cv-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] h1,
:root[data-theme="light"] h2 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] li {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] p {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] i {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] .award {
  color: var(--award-color, #b8860b);
}

:root[data-theme="light"] a {
  color: var(--link-color, #2ecc71);
}

:root[data-theme="light"] a:hover {
  color: var(--link-hover-color, #27ae60);
}
</style>
