<template>
  <div class="teaching-page">
    <h1>Teaching</h1>
    
    <!-- Current Courses -->
    <section class="content-section">
      <h2>Current Courses</h2>
      <div class="grid-container">
        <div v-for="(course, index) in currentCourses" :key="index" class="course-card">
          <h3>{{ course.title }}</h3>
          <p class="subtitle">{{ course.code }} | {{ course.semester }}</p>
          <p class="description">{{ course.description }}</p>
          <div class="course-details">
            <div class="detail-item">
              <i class="bi bi-clock"></i>
              <span>{{ course.schedule }}</span>
            </div>
            <div class="detail-item">
              <i class="bi bi-geo-alt"></i>
              <span>{{ course.location }}</span>
            </div>
          </div>
          <div class="button-group">
            <a :href="course.syllabus" class="link-button">Syllabus</a>
            <a v-if="course.materials" :href="course.materials" class="link-button">Course Materials</a>
          </div>
        </div>
      </div>
    </section>

    <!-- Teaching Resources -->
    <section v-if="resources.length > 0" class="content-section">
      <h2>Teaching Resources</h2>
      <div class="grid-container">
        <div v-for="(resource, index) in resources" :key="index" class="resource-card">
          <h3>{{ resource.title }}</h3>
          <p class="description">{{ resource.description }}</p>
          <a :href="resource.link" class="link-button">Access Resource</a>
        </div>
      </div>
    </section>

    <!-- Office Hours -->
    <!-- <section v-if="officeHours.length > 0" class="content-section">
      <h2>Office Hours</h2>
      <div class="office-hours-card">
        <div class="office-hours-grid">
          <div class="hours-section">
            <h3>Regular Office Hours</h3>
            <ul class="hours-list">
              <li v-for="(hours, index) in officeHours" :key="index">
                <i class="bi bi-calendar-event"></i>
                {{ hours.day }}: {{ hours.time }}
              </li>
            </ul>
          </div>
          <div class="appointment-section">
            <h3>Book an Appointment</h3>
            <p>Need to meet outside regular office hours? Book a slot below:</p>
            <button class="link-button" @click="bookAppointment">
              Schedule Meeting
            </button>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'TeachingPage',
  data() {
    return {
      currentCourses: [
        {
          title: 'Finansiell Styring',
          code: 'BØK 3632',
          semester: 'Fall 2024',
          description: 'A corporate finance course in Norwegian.',
          schedule: 'Mondays and Wednesdays',
          location: 'Finn Øien auditorium',
          syllabus: 'https://programmeinfo.bi.no/nb/kurs/B%C3%98K-3632/2024-host',
        }
      ],
      resources: [
        {
          title: 'International Finance Lecture Notes',
          description: 'Various material for international finance.',
          link: '#'
        }
      ],
      // officeHours: [
      //   { day: 'Monday', time: '13:00-15:00' },
      //   { day: 'Wednesday', time: '13:00-15:00' },
      //   { day: 'Friday', time: '10:00-12:00' }
      // ]
    }
  },
  methods: {
    bookAppointment() {
      console.log('Booking appointment...');
    }
  }
}
</script>

<style scoped>
.teaching-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: clamp(1.5rem, 4vw, 2.5rem) 0;
  font-size: clamp(1.5rem, 4vw, 2rem);
}

h2 {
  color: var(--text-color, #ffffff);
  margin-bottom: clamp(1rem, 3vw, 1.25rem);
  font-size: clamp(1.25rem, 3vw, 1.5rem);
}

h3 {
  color: var(--text-color, #ffffff);
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  margin-bottom: 0.75rem;
}

.content-section {
  margin-bottom: clamp(2rem, 5vw, 3rem);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  gap: clamp(1rem, 3vw, 1.25rem);
}

.course-card, .resource-card, .office-hours-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: clamp(1rem, 3vw, 1.25rem);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.course-card:hover, .resource-card:hover, .office-hours-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.subtitle {
  color: var(--accent-color, #4a90e2);
  font-size: clamp(0.9rem, 2vw, 1rem);
  margin-bottom: 0.5rem;
}

.description {
  color: var(--text-secondary, #cccccc);
  line-height: 1.6;
  margin-bottom: 0.75rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.course-details {
  margin: 0.75rem 0;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: var(--text-secondary, #cccccc);
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.detail-item i {
  margin-right: 0.5rem;
  color: var(--accent-color, #4a90e2);
}

.button-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: auto;
}

.link-button {
  padding: clamp(0.4rem, 2vw, 0.6rem) clamp(0.8rem, 3vw, 1rem);
  border-radius: 4px;
  text-decoration: none;
  font-size: clamp(0.85rem, 2vw, 0.9rem);
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--accent-color, #4a90e2);
  color: white;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 120px;
  text-align: center;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
}

.office-hours-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  gap: clamp(1rem, 3vw, 1.25rem);
}

.hours-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hours-list li {
  color: var(--text-secondary, #cccccc);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.hours-list i {
  margin-right: 0.5rem;
  color: var(--accent-color, #4a90e2);
}

/* Light mode styles */
:root[data-theme="light"] .course-card,
:root[data-theme="light"] .resource-card,
:root[data-theme="light"] .office-hours-card {
  background-color: var(--card-bg, #f5f5f5);
}

:root[data-theme="light"] .course-card:hover,
:root[data-theme="light"] .resource-card:hover,
:root[data-theme="light"] .office-hours-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] h1,
:root[data-theme="light"] h2,
:root[data-theme="light"] h3 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .description,
:root[data-theme="light"] .detail-item,
:root[data-theme="light"] .hours-list li {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] p {
  color: var(--text-secondary, #666666);
}

@media (max-width: 480px) {
  .button-group {
    flex-direction: column;
  }

  .link-button {
    width: 100%;
  }
}
</style>
