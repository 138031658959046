<template>
  <div class="mingly">
    <h1>Mingly</h1>
    
    <div class="content-section">
      <div class="text-card intro-card">
        <p>During the early days of COVID, I realized that the current video conferencing solutions did not work well in social settings. Only one can speak at a time, forcing everyone in the room to participate in the same conversation. This is very different from meeting up in-person, where multiple conversations are happening simultaneously.</p>
        <p>When I started teaching online, I also realized that it was difficult to create bonds between students for exactly the same reason. Setting up break out rooms was cumbersome, and the "walls" between the rooms created artificial barriers that made quick interactions almost impossible.</p>
        <p>I started looking into apps that would allow for a "spatial dimension," but to my disappointment I was not able to find one. I therefore decided to look into the possibility of developing such an app myself. This was the start of Mingly.</p>
      </div>
    </div>

    <div class="content-section">
      <div class="media-text-container">
        <div class="media-container">
          <img src="/media/draganddrop.gif" alt="Mingly Drag and Drop Demo" class="demo-gif">
        </div>
        <div class="text-card">
          <p>Mingly is a browser based video chat application that has the spatial dimension. You can "move" around in a room, and you hear people close to you better. In this way, multiple conversations can happen simultaneously in the same room. Mingly tears down the walls between the breakout rooms, creating a much more organic way of letting people choose who they want to talk with at any given moment.</p>
        </div>
      </div>
    </div>

    <div class="content-section">
      <div class="media-text-container">
        <div class="text-card">
          <p>When developing Mingly, the focus has been on making the user interface as intuitive as possible. If you have used google maps, the feel in Mingly should be familiar. You can easily get an overview of the room by zooming out, quickly moving around by click-and-drag, and easily focus the screen just as you like it.</p>
        </div>
        <div class="media-container">
          <img src="/media/zoom.gif" alt="Mingly Zoom Interface Demo" class="demo-gif">
        </div>
      </div>
    </div>

    <div class="content-section">
      <div class="text-card intro-card">
        <p>Mingly is still in an early stage of development, but if you like to try it out you can click on the link below. For the best experience you should use a PC/Mac or laptop. It works on smartphones and tablets, but it is not yet optimized. Some features are only available if you use Chrome or Edge. Also note that you need to accept that Mingly gets access to your camera and microphone. If you would like a personalized room, just send an email to christian@mingly.world.</p>
      </div>
    </div>

    <div class="button-container">
      <a href="https://mingly.world/login?room=MinglyFriends&password=Friends2022" target="_blank" class="link-button try-mingly">Try Mingly</a>
      <a href="https://www.youtube.com/channel/UCuQtsMNKxkybz47FNDLxVoA" target="_blank" class="link-button videos">Illustration Videos</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MinglyApp'
}
</script>

<style scoped>
.mingly {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin: 40px 0;
}

.content-section {
  margin-bottom: 40px;
}

.text-card {
  background-color: var(--card-bg, #2a2a2a);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  color: var(--text-color, #cccccc);
  width: 50%;
}

.intro-card {
  width: 70%;
  margin: 0 auto;
}

.text-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: var(--card-hover-bg, #333333);
}

.text-card p {
  margin-bottom: 15px;
  line-height: 1.6;
  color: var(--text-color, #cccccc);
}

.text-card p:last-child {
  margin-bottom: 0;
}

.media-text-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.media-container {
  width: 50%;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--card-bg, #2a2a2a);
}

.demo-gif {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.link-button {
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;
  color: white;
}

.link-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
  color: white;
}

.try-mingly {
  background-color: #2ecc71;
}

.videos {
  background-color: #9b59b6;
}

@media (max-width: 768px) {
  .media-text-container {
    flex-direction: column;
  }
  
  .text-card, .media-container, .intro-card {
    width: 100%;
  }
  
  .text-card {
    padding: 15px;
  }
}

/* Light mode styles */
:root[data-theme="light"] .text-card {
  background-color: var(--card-bg, #f5f5f5);
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .text-card:hover {
  background-color: var(--card-hover-bg, #e8e8e8);
}

:root[data-theme="light"] .text-card p {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] h1 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .media-container {
  background-color: var(--card-bg, #f5f5f5);
}
</style>
