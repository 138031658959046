<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <Analytics />
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container">
        <router-link class="navbar-brand" to="/">Christian Heyerdahl-Larsen</router-link>
        <div class="d-flex align-items-center">
          <button @click="toggleDarkMode" class="btn mode-switch me-2 d-lg-none" :title="isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'">
            <i class="bi" :class="isDarkMode ? 'bi-sun' : 'bi-moon'"></i>
          </button>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="bi bi-list"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact>Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/research">Research</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/discussions">Discussions</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/mingly">Mingly</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/teaching">Teaching</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/cv">CV</router-link>
            </li>
          </ul>
          <button @click="toggleDarkMode" class="btn mode-switch d-none d-lg-flex" :title="isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'">
            <i class="bi" :class="isDarkMode ? 'bi-sun' : 'bi-moon'"></i>
          </button>
        </div>
      </div>
    </nav>
    <main class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Analytics } from '@vercel/analytics/vue';

const isDarkMode = ref(true);

function toggleDarkMode() {
  isDarkMode.value = !isDarkMode.value;
  localStorage.setItem('darkMode', isDarkMode.value.toString());
  document.documentElement.classList.toggle('dark-mode');
}

onMounted(() => {
  const savedDarkMode = localStorage.getItem('darkMode');
  if (savedDarkMode !== null) {
    isDarkMode.value = JSON.parse(savedDarkMode);
    if (isDarkMode.value) {
      document.documentElement.classList.add('dark-mode');
    }
  } else {
    isDarkMode.value = true;
    document.documentElement.classList.add('dark-mode');
  }

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    if (localStorage.getItem('darkMode') === null) {
      isDarkMode.value = e.matches;
      document.documentElement.classList.toggle('dark-mode', e.matches);
    }
  });
});
</script>

<style>
:root {
  --primary-bg: #ffffff;
  --secondary-bg: #f8f9fa;
  --text-color: #2c3e50; /* Ensure this is a readable color for light mode */
  --nav-bg: rgba(255, 255, 255, 0.95);
  --card-bg: #ffffff;
  --border-color: #e9ecef;
  --hover-color: #e9ecef;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.dark-mode {
  --primary-bg: #1a1a1a;
  --secondary-bg: #2d2d2d;
  --text-color: #e1e1e1;
  --nav-bg: rgba(26, 26, 26, 0.95);
  --card-bg: #2d2d2d;
  --border-color: #404040;
  --hover-color: #404040;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

#app {
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
  color: var(--text-color);
  min-height: 100vh;
  transition: all 0.3s ease;
}

.navbar {
  background-color: var(--nav-bg);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0;
}

.navbar-brand, .nav-link {
  color: var(--text-color) !important;
  position: relative;
}

.nav-link {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem !important;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.nav-link:hover, .nav-link.router-link-active {
  background-color: var(--hover-color);
}

.navbar-toggler {
  border: none;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--text-color);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler i {
  display: block;
  line-height: 1;
}

.main-content {
  padding-top: 80px;
  padding-bottom: 2rem;
  min-height: calc(100vh - 80px);
}

.mode-switch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.mode-switch:hover {
  background-color: var(--hover-color);
}

.mode-switch i {
  font-size: 1.2rem;
}

/* Card styles */
.card {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px var(--shadow-color);
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    background-color: var(--nav-bg);
    padding: 1rem;
    border-radius: 12px;
    margin-top: 1rem;
    border: 1px solid var(--border-color);
  }

  .nav-link {
    margin: 0.25rem 0;
  }

  .mode-switch {
    margin: 0.5rem 0;
  }
}
</style>
